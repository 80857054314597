<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      'Activation › Voucher': 'Ativação › Voucher',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Status',
      'Details': 'Detalhes',
      'Pending': 'Pendente',
      'Approved': 'Aprovado',
      'Canceled': 'Cancelado',
      'Value': 'Valor',
      'Balance': 'Saldo',
      'Create Voucher': 'Criar Voucher',
      'Create': 'Criar',
      'The minimum value is R$ 50,00.': 'O valor mínimo é de R$ 50,00',
      'You do not have enough activation balance.': 'Você não possui saldo de ativação suficiente',
      'The voucher was created successfully.': 'O voucher foi criado com sucesso.',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Activation › Voucher': 'Activación › Cupón',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Estado',
      'Details': 'Detalles',
      'Pending': 'Pendiente',
      'Approved': 'Aprobado',
      'Canceled': 'Cancelado',
      'Value': 'Valor',
      'Balance': 'Equilibrio',
      'Create Voucher': 'Crear Cupón',
      'Create': 'Crear',
      'The minimum value is R$ 50,00.': 'El valor mínimo es de R$ 50,00.',
      'You do not have enough activation balance.': 'Usted no tiene saldo suficiente activación.',
      'The voucher was created successfully.': 'El cupón se ha creado correctamente.',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },

      table: {
        heade: [
          'Date', 'Code', 'Value', 'Status'
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      voucher: {
        modal: false,
        alert: {
          type: '',
          message: ''
        },

        value: '0,00',

        success: {
          status: false,
          code: '',
          value: '',
        }
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    voucher: {
      value: { required }
    },
  },
  methods: {
    getList() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('wallet/activation/voucher')
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
          } else {
            this.table.body = null
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    },
    voucherSubmit() {
      this.$v.voucher.$touch();

      if (this.voucher.value) {
        this.voucher.loading = true

        api
          .post('wallet/activation/voucher', {
            value: this.voucher.value,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.voucher.value = '0,00'
              this.$v.voucher.$reset()

              this.voucher.alert.type = 'alert-success'
              this.voucher.alert.message = response.data.message

              this.voucher.success.status = true
              this.voucher.success.code = response.data.voucher.code
              this.voucher.success.value = response.data.voucher.value

              this.getList();
            } else {
              this.voucher.alert.type = 'alert-danger'
              this.voucher.alert.message = response.data.message
            }

            this.voucher.loading = false
          })
      }
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Activation › Voucher') }}</h4>
          <div class="page-title-right">
            <button
              type="button"
              class="btn btn-dark btn-sm text-uppercase"
              @click="voucher.modal = true; voucher.success.status = false; voucher.success.code = ''; voucher.status.value = ''"
            >{{ t('Create Voucher') }}</button>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="voucher.modal" :title="t('Create Voucher')" centered>
      <div v-if="voucher.success.status" class="text-center pb-5">
        <div>{{ t('The voucher was created successfully.') }}</div>
        <h2 class="mt-5 pt-3 font-weight-bold text-warning">
          {{ voucher.success.code }}
        </h2>
        <h4>{{ voucher.success.value }}</h4>
      </div>
      <b-form v-else @submit.prevent="voucherSubmit">
        <div v-if="voucher.alert.message" :class="'alert ' + voucher.alert.type">{{ t(voucher.alert.message) }}</div>
        <b-form-group id="voucher-value" :label="t('Value')" label-for="voucher-value">
          <b-form-input id="voucher-value" v-model="voucher.value" v-money="money" type="text" :class="{ 'is-invalid': $v.voucher.value.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.voucher.value.$error" class="invalid-feedback">
            <span v-if="!$v.voucher.value.required">{{ t('Value is required.') }}</span>
          </div>
        </b-form-group>
        <b-button :disabled="!this.voucher.value || this.voucher.value==='R$ 0,00'" type="submit" variant="dark">{{ t('Create') }}</b-button>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td v-for="(data,row) in td" :key="row">
                      <div v-if="row === 'code'">
                        {{ data }}
                        <button class="btn btn-link p-0 ml-2" v-clipboard:copy="data"><i class="bx bx-copy font-size-18 text-warning"></i></button>
                      </div>
                      <div v-else-if="row === 'status'">
                        <span v-if="data === 'available'" class="badge badge-soft-warning font-size-12">{{ t('Available') }}</span>
                        <span v-else-if="data === 'used'" class="badge badge-soft-success font-size-12">{{ t('Used') }}</span>
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>